<template>
  <div>
    <p
      class="text-center font-bold"
    >
      {{ formTitle }}
    </p>

    <div
      class="mt-8"
    >
      <form-group
        :rules="`${requiredIfGuest}|email`"
        name="guest.email"
        type="email"
        :hint="`${t('ttmt.form.hints.auth.register.employee.not_duplicate_email')}
          ${t(`enums.App\\Enums\\UserRoleEnum.${form.values?.context?.roles?.manager}`)} /
          ${t(`enums.App\\Enums\\UserRoleEnum.${form.values?.context?.roles?.employee}`)}`"
        :form-control-props="{
          iconProps: { library: 'coolicon', name: 'mail' },
        }"
      />

      <!-- Password confirmation -->
      <form-group
        :rules="`${requiredIfGuest}|confirmed:@guest.password`"
        name="guest.password"
        type="password"
        hide-errors
        :form-control-props="{
          autocomplete: 'new-password',
          iconProps: { library: 'local', name: 'lock' },
        }"
      >
        <template #hint>
          <form-password-hint-list
            :password="form.values.guest?.password"
          />
        </template>
      </form-group>

      <!-- Password confirmation -->
      <form-group
        :rules="`${requiredIfGuest}|confirmed:@guest.password`"
        name="guest.password_confirmation"
        :hint="`${t('ttmt.form.hints.auth.register.employee.not_duplicate_password')} ${t(`enums.App\\Enums\\UserRoleEnum.${form.values?.context?.roles?.manager}`)} / ${t(`enums.App\\Enums\\UserRoleEnum.${form.values?.context?.roles?.employee}`)}`"
        type="password"
        :form-control-props="{
          autocomplete: 'new-password',
          iconProps: { library: 'local', name: 'lock' },
        }"
      />
    </div>
    <div
      class="flex"
    >
      <app-button
        padding="py-3 px-8"
        type="button"
        display-classes="flex"
        class="min-w-fit max-w-8/10 mx-auto mt-8"
        :label="t('ttmt.common.actions.return')"
        @click="back"
      />

      <app-button
        padding="py-3 px-8"
        type="button"
        :disabled="!isFormFilled"
        display-classes="flex"
        class="min-w-fit max-w-8/10 mx-auto mt-8"
        :label="t('ttmt.common.actions.next')"
        @click="next"
      />
    </div>
  </div>
</template>

<script setup>
import { capitalize } from 'lodash'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import useFormFields from '@shared/hooks/form/formFields'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormPasswordHintList from '@shared/components/form/password_hint/FormPasswordHintList.vue'

const emits = defineEmits([
  'next',
  'back',
])

const props = defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const { t } = useI18n()
const { form } = useFormFields(props)

const isFormFilled = computed(() => (
  form.value.values.guest?.email
    && form.value.values.guest?.password
    && form.value.values.guest?.password_confirmation
))

// ---------- COMPUTED ----------

const requiredIfGuest = computed(() => (
  form.values?.context?.has_guest_account ? 'required' : ''
))

const formTitle = computed(() => (
  capitalize(`${t('ttmt.auth.extranet_register.form.form_title')} ${t('enums.App\\Enums\\UserRoleEnum.guestTraveller')}`)
))

// ---------- STEPS ----------

function next() {
  emits('next')
}

function back() {
  emits('back')
}
</script>
